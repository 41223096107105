<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      ok-title="Save"
      header-close-content=""
      title="Update Listener Log 2"
      centered
      scrollable
    >
      <b-container fluid>
        <b-row>
          <b-col v-for="([key], index) in Object.entries(editedItem)" :key="index" cols="12">
            <b-form-group :id="key">
              <label for="nameInput">{{ key }} <span class="text-danger">*</span></label>
              <b-form-input
                :id="key + '_input'"
                :name="key"
                v-model="editedItem[key]"
                type="text"
                :class="{ input: true, 'is-danger': errors.has(`${key}`) }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Listener Log 2</h4>
      </b-col>
      <!-- <b-col cols="2">
        <b-form-group label="Payment" label-for="payment_filter">
          <b-form-select
            id="payment_filter"
            v-model="filterPaymentValue"
            :options="filterPaymentList"
            @change="handleChangeFilter"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Status" label-for="status_filter">
          <b-form-select
            id="status_filter"
            v-model="filterStatusValue"
            :options="filterStatusList"
            @change="handleChangeFilterStatus"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col> -->
      <!-- Filter UserName -->
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="listLogHistoryData.data.map((item) => ({ ...item, action: '' }))"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
      :busy="isLoading"
    >
      <!-- HEAD -->
      <template #head(listener_log)="data">
        <div class="mb-1">{{ data.label }}</div>
        <b-button-group v-if="listLogHistoryData.data.length > 0" size="sm">
          <b-button
            v-for="h in Object.keys(listLogHistoryData.data[0].listener_log)"
            :key="h"
            @click="handleFilterTableHead('listener_log', h)"
            :class="{
              'text-primary ': filterTableHead.listener_log === h
            }"
            >{{ h }}</b-button
          >
        </b-button-group>
      </template>

      <template #head(format)="data">
        <div class="mb-1">{{ data.label }}</div>
        <b-button-group v-if="listLogHistoryData.data.length > 0" size="sm">
          <b-button
            v-for="h in Object.keys(listLogHistoryData.data[0].format)"
            :key="h"
            @click="handleFilterTableHead('format', h)"
            :class="{
              'text-primary ': filterTableHead.format === h
            }"
            >{{ h }}</b-button
          >
        </b-button-group>
      </template>

      <template #head(payment_method)="data">
        <div class="mb-1">{{ data.label }}</div>
        <b-button-group v-if="listLogHistoryData.data.length > 0" size="sm">
          <b-button
            v-for="h in Object.keys(listLogHistoryData.data[0].payment_method)"
            :key="h"
            @click="handleFilterTableHead('payment_method', h)"
            :class="{
              'text-primary ': filterTableHead.payment_method === h
            }"
            >{{ h }}</b-button
          >
        </b-button-group>
      </template>

      <!-- CELL -->
      <template #cell(listener_log)="item">
        <div>{{ item.value[filterTableHead.listener_log] || '-' }}</div>
      </template>
      <template #cell(format)="item">
        <div>{{ item.value[filterTableHead.format] || '-' }}</div>
      </template>
      <template #cell(payment_method)="item">
        <div>{{ item.value[filterTableHead.payment_method] || '-' }}</div>
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Fail</b-badge>
      </template>

      <!-- <template #cell(format)="item"> </template> -->

      <template #cell(actions)="item">
        <v-icon
          v-if="item.item.amount && item.item.payment_id && item.item.phone_number && item.item.status !== 1"
          class="text-success"
          @click="handleRechargeItem(item.item)"
          >mdi-pencil</v-icon
        >
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        {{ footerTableInfo }}
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="getData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="listLogHistoryData.total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="getData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/common/api.service';
// import TabinTable from './TabinTable';
export default {
  // components: { TabinTable },
  data() {
    return {
      listLogHistoryData: {
        data: [],
        total: 0
      },

      filterPaymentList: [
        { value: 1, text: 'Payment 1' },
        { value: 2, text: 'Payment 2' }
      ],
      filterStatusList: [
        { value: 1, text: 'Status 1' },
        { value: 2, text: 'Status 2' }
      ],

      filterPaymentValue: null,
      filterStatusValue: null,

      perPage: 10,
      currentPage: 1,

      isLoading: true,

      filterTableHead: {
        listener_log: 'id',
        format: 'id',
        payment_method: 'id'
      },
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Listener log', key: 'listener_log' },
        { text: 'Format', key: 'format' },
        { text: 'Payment', key: 'payment_method' },
        { text: 'Phone Number', key: 'phone_number' },
        { text: 'Amount', key: 'amount' },
        { text: 'Notice', key: 'notice' },
        { text: 'Status', key: 'status' },
        { text: 'Action', key: 'actions' }
      ],
      editedItem: {
        id: '',
        listener_log: '',
        format: '',
        payment_method: '',
        phone_number: '',
        amount: '',
        notice: '',
        status: ''
      },

      dialog: false
    };
  },
  created() {
    this.getData();
  },
  computed: {
    paramsListNotifyHistory() {
      return { params: { limit: this.perPage, offset: this.currentPage } };
    },
    footerTableInfo() {
      const { currentPage, perPage, listLogHistoryData } = this;
      const rows = listLogHistoryData.total;
      return `Showing ${(currentPage - 1) * perPage + 1}-${currentPage * perPage > rows ? rows : currentPage * perPage}
        of ${rows}.`;
    }
  },
  methods: {
    handleChangeFilter() {},
    handleChangeFilterStatus() {},
    handleFilterTableHead(key, value) {
      this.filterTableHead[key] = value;
    },
    handleRechargeItem: function (item) {
      // this.dialog = true;
      // this.editedItem = item;
      Swal.fire({
        title: item.listener_log.message,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Recharge',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: () => {
          const { data } = this.$store.getters.currentUser;
          return ApiService.post(`/deposit/${data.account_id}/recharge-deposit`, {
            amount: item.amount,
            payment_id: item.payment_id,
            phone: item.phone_number,
            action_id: item.id
          })
            .then((response) => {
              if (!response.ok) {
                this.getData();
                Swal.fire({
                  icon: 'success',
                  title: 'Recharge success !',
                  showConfirmButton: false,
                  timer: 2000
                });
              }
            })
            .catch((error) => {
              const { message } = error.response?.data?.data;
              Swal.showValidationMessage(message || 'Try again !');
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url
          });
        }
      });
    },
    async getData() {
      this.isLoading = true;
      try {
        const dataRes = await ApiService.query('/payment/notify-listener-history', this.paramsListNotifyHistory).then(
          (res) => res.data
        );
        const { status, data } = dataRes;
        if (status) {
          this.listLogHistoryData = data;
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: "Can't load data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style></style>
